:root {
    --bg-color: #070707;
    --bg-color2: #141414;
    --bg-color3:  rgba(20, 20, 20, 0.60);
    --bg-color4: #fff;
    --heading-size: 36px;
    --headings-color: #fff;
    --card-padding: 20px 16px;
    --tablehead-padding: 12px 16px;
    --desc-color: #fff;
    --desc-color-dark: #141414;
    --desc-color-lite: #ffffff66;
    --desc-color-grey: rgba(255, 255, 255, 0.80);
    --color-neon: #00FF8D;
    --color-green: #00CC71;
    --color-red : rgba(255, 18, 18, 1);
    --color-yellow: rgba(232, 211, 63, 1);
    --icons-color: #fff;
    --activeicons-color: #00FF8D;
    --active-shadow: 0px 0px 40px 0px rgba(0, 255, 141, 0.36);
    --active-bg: rgba(0, 255, 141, 0.10);
    --active-bgselect: rgba(0, 255, 141, 0.10);
    --xl-fontsize : 18px;
    --default-fontsize: 16px;
    --large-fontsize: 14px;
    --medium-fontsize: 12px;
    --xsmall-fontsize: 10px;
    --value10: 10px;
    --value24: 24px;
    --value12: 12px;
    --value16: 16px;
    --value8: 8px;
    --border-color: rgba(255, 255, 255, 0.20);
    --default-margin-bottom: 24px;
    --default-row-gap:20px;
    --font-300: 300;
    --font-400: 400;
    --font-600: 600;
    --font-700: 700;
    --font-800: 800;
}

@font-face {
    font-family: "SF Pro Display";
    src: url("../fonts/SFProDisplay/SF-Pro-Display-Bold.otf") format("opentype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "SF Pro Display";
    src: url("../fonts/SFProDisplay/SF-Pro-Display-Medium.otf") format("opentype");
    font-weight: 400;
  }
  
  @font-face {
    font-family: "SF Pro Display";
    src: url("../fonts/SFProDisplay/SF-Pro-Display-Regular.otf") format("opentype");
    font-weight: 300;
  }
  
  @font-face {
    font-family: "SF Pro Display";
    src: url("../fonts/SFProDisplay/SF-Pro-Display-Heavy.otf") format("opentype");
    font-weight: 700;
  }
  body{
    background-color: var( --bg-color);
    font-family: "SF Pro Display";
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    &::-webkit-scrollbar:vertical {
        width: 3px;
        background-color: var(--bg-color3)
        // border: 2px solid var(--color-neon);
    }
    
    &::-webkit-scrollbar:horizontal {
        // height: 3px;
        display: none;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 1px solid var(--color-neon);
        background-color: var(--color-neon);
    }
    *{
        font-family: "SF Pro Display";
        font-weight: var(--font-300);
        strong{
            *{
                font-weight: var(--font-800);
            }
        }
    }
    h1{
        font-size: var(--heading-size);
        margin-bottom: var(--default-margin-bottom);
    }
    h2{
        font-size: var(--value24);
        margin-bottom: var(--default-margin-bottom);
    }
    h3{
        font-size: var(--value16);
        margin-bottom: var(--default-margin-bottom);
    }

  }
.lightchart{
    display: none;
}
  
div[data-theme="light-theme"] {
    --bg-color: #fff;
    --headings-color: #000000;
    --desc-color: #000000;
    --desc-color-grey: rgba(0, 0, 0, 0.80);
    --bg-color2: #F0F0F0;
    --bg-color3:  #FAFAFA;
    --bg-color4:#141414;
    --icons-color: #141414;
    --activeicons-color: #141414;
    --active-bg: #00FF8D;
    --border-color: #EAEAEA;
    --desc-color-lite: #141414;
    --active-bgselect: #000000bf;
    .darkchart{
        display: none;
    }
    .lightchart{
        display: block;
    }
    .conversion-topbar .icon .user-initial {
        background-color: #070707;
    }
}

h1, h2, h3, h4, h5, h6, ul li{
    color: var(--headings-color);
}
strong{
    font-weight: var(--font-800);
}
p, blockquote, pre, a, code{
    color: var(--desc-color);
    font-size: var(--default-fontsize);
}
body {
    ul, ol{
        padding-left: 0px;
    }
}
.radius-2{
    border-radius: 2px;
}
.radius-4{
    border-radius: 4px;
}
button, input, select, textarea, label{
    font-size: var(--default-fontsize);
}
div.conversion_full_page {
    background: var(--bg-color) !important;
    font-family: "SF Pro Display";
    color: var(--desc-color);
  }
.flex{
    display: flex;
}
.flex-column{
    flex-direction: column;
}
.flex-row{
    flex-direction: row;
}
.inline-flex{
    display: inline-flex;
}
.pointer{
    cursor: pointer;
}
.align-center{
    align-items: center;
}
.align-start{
    align-items: flex-start;
}
.align-end{
    align-items: flex-end;
}
.green{
    color: var(--color-green);
}
.neon{
    color: var(--color-neon);
}
.red{
    color: var(--color-red);
    svg{
        path{
            fill: var(--color-red);
        }
    }
}
.disabled-acc{
    background-color: var(--bg-color2);
    opacity: 0.6;
}
.active-bg{
    background: var(--active-bg);
}
.bg-yellow{
    background: var(--color-yellow);
    color: var(--bg-color);
}
.yellow{
    color: var(--color-yellow);
    svg{
        path{
            fill: var(--color-yellow);
        }
    }
}
.dropdown-toggle::after{
    display: none;
}
.flex-wrap{
    flex-wrap: wrap;
}
.full-width{
    width: 100%;
    max-width: 100%;
}
.full-height{
    height: 100%;
    max-height: 100%;
}
// 
.bg-1{
    background-color: var(--bg-color);
}
.bg-2{
    background-color: var(--bg-color2);
}

.big-padding{
    padding: var(--value24);
}
.big-padding-top{
    padding-top: var(--value24);
}
.big-padding-bottom{
    padding-bottom: var(--value24);
}
.big-padding-left{
    padding-left: var(--value24);
}
.big-padding-right{
    padding-right: var(--value24);
}

// 
.section-pd{
    padding: 36px 0px;
}
.border-bottom{
    border-bottom: 1px solid var(--border-color)!important;
}
.section-pd-top{
    padding-top: 36px;
}
.section-pd-bottom{
    padding-bottom: 36px;
}
.justify-center{
    justify-content: center;
}
.default-padding{
    padding: var(--value12);
}
.default-padding-top{
    padding-top: var(--value12);
}
.default-padding-bottom{
    padding-bottom: var(--value12);
}
.default-padding-left{
    padding-left: var(--value12);
}
.default-padding-right{
    padding-right: var(--value12);
}
.card-padding{
    padding: var(--card-padding);
}
.default-margin-bottom{
    margin-bottom: var(--value12);
}
.default-margin-top{
    margin-top: var(--value12);
}
.default-margin-left{
    margin-left: var(--value12);
}
.default-margin-right{
    margin-right: var(--value12);
}
.default-margin{
    margin: var(--value12);
}


.big-margin-bottom{
    margin-bottom: var(--value16);
}
.big-margin-top{
    margin-top: var(--value16);
}
.big-margin-left{
    margin-left: var(--value16);
}
.big-margin-right{
    margin-right: var(--value16);
}
.big-margin{
    margin: var(--value16);
}


.default-column-gap{
    column-gap: var(--default-row-gap);
}
.default-row-gap{
    row-gap: var(--default-row-gap);
}
.default-border{
    border: 1px solid var(--border-color);
}
.default-border-bottom{
    border-bottom: 1px solid var(--border-color);
}
.default-border-left{
    border-left: 1px solid var(--border-color);
}
.default-border-right{
    border-right: 1px solid var(--border-color);
}
.justify-end{
    justify-content: flex-end;

}
.justify-space-between{
    justify-content: space-between;
}
.justify-space-around{
    justify-content: space-around;
}
.neon-shadow{
    box-shadow: var(--active-shadow);
}
.justify-space-evenly{
    justify-content: space-evenly;
}
.margin-auto{
    margin: auto;
}
.margin-top-auto{
    margin-top: 0!important;
}
.margin-right-auto{
    margin-right: auto;
}
.margin-bottom-auto{
    margin-bottom: 0!important;
}
.margin-left-auto{
    margin-left: auto;
}
.pad-16{
    padding:  var(--value16);
}
.scrubber-wrapper{
    overflow: scroll;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: #000000e0;
    display: flex;
    align-items: center;
    justify-content: center;
    .scrubber-inner{
        max-width: 768px;
        max-height: 96vh;
        overflow: scroll;
        margin: auto;
        border-radius: 10px;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        
        &::-webkit-scrollbar:vertical {
            width: 6px;
            background-color: var(--bg-color3)
            // border: 2px solid var(--color-neon);
        }
        
        &::-webkit-scrollbar:horizontal {
            // height: 3px;
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 1px solid var(--color-neon);
            background-color: var(--color-neon);
        }
    }
}
.mab-tag{
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    background: var(--color-neon);
    color: #070707;
    padding: 1px 2px;
    font-size: 10px;
}
.report-data-table{
    &:not(.no-overflow){
        overflow: scroll!important;
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    &::-webkit-scrollbar:vertical {
        display: none;
    }
    
    &::-webkit-scrollbar:horizontal {
        height: 3px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid var(--color-neon);
        background-color: rgba(0, 0, 0, .5);
        // background-color: red;
    }
    table{
        width: 100%;
        // table-layout: fixed;
        tr{
            &.header{
                td{
                    padding: var(--tablehead-padding);
                    text-transform: uppercase;
                    line-height: normal;
                    font-weight: var(--font-800);
                    font-size: var(--value10);
                    color: var(--desc-color-lite);
                }   
            }
            &.data{
                    td{
                        padding: var(--card-padding);
                        text-transform: uppercase;
                        font-size: var(--large-fontsize);
                        font-weight: var(--font-400);
                    }
            }
            td{
                // vertical-align: middle;
                text-wrap: nowrap;
            }
        }
    
    }
    &.oddevenpattern{
        tr{
            &:nth-child(even){
                background-color: var(--bg-color2);
            }
        }
    }
}

.mde-cal-wrapper{
    .mde-cal-inner{
        position: relative;
        .mde-cal-values{
            padding: 16px 0px 0px;
            >h2{
                text-align: center;
                padding: 0px 16px;
                min-height: 48px;
            }
            .smart-input{
                .smart-input-cover{
                    border-left: none;
                    border-right: none;
                    background: transparent;
                }
                margin-bottom: 0px;
                >label{
                    display: none;
                }
                input{
                    text-align: center;
                    background:transparent;
                }
            }
            .closest{
                &::before{
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 54px;
                    border: 1px solid var(--color-green);
                    z-index: 0;
                }
                &::after{
                    content: "Suggested";
                    position: absolute;
                    transform: translate(3px, -100%);
                    background: var(--color-green);
                    height: 22px;
                    padding: 2px 5px;
                    color: #000;
                }
            }
        }
        .mde-cal-form{
            position: relative;
            z-index: 1;
        }
    }
}
.datepicker-wrapper{
        &.showpredefinedranges{
            
            .rdrDateRangePickerWrapper{
                // border-bottom: 1px solid var(--border-color);
            }
            .rdrDefinedRangesWrapper{
                display: block;
                border: none;
                .rdrStaticRanges{
                    button{
                        background-color: var(--base-background-card);
                        border: none;
                        &:hover{
                            // background-color: var(--base-background-card);
                            
                            .rdrStaticRangeLabel{
                                background-color: rgba(61, 97, 185, 0.5);
                                color: var(--desc-color);
                            }
                        }
                    }
                }
                .rdrInputRanges{
                    display: none;
                }
                
            }
            .buttons-operations{
                border-top: 1px solid var(--border-color);
            }
        }
}
