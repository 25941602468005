@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;900&display=swap");
@font-face {
  font-family: "SF Pro Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Bold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Medium.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Heavy.otf") format("opentype");
  font-weight: 700;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
.logo-holder {
  text-align: center;
}
.conversionapppage{
  min-height: 100vh;
}
.p-24{
  padding: 24px;
}
.p-24-top{
  padding-top: 24px;
}
.p-24-bottom{
  padding-bottom: 24px;
}
.p-20{
  padding: 20px;
}
.p-20-top{
  padding-top: 20px;
}
.p-20-bottom{
  padding-bottom: 20px;
}
.logo-holder img {
  max-width: 382px;
  display: inline-block;
  width: 100%;
}
/* .header-wrapper .header-inner {
  width: 100%;
  max-width: calc(100% - 315px);
  margin-left: calc(315px);
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19.25px 40px;
} */
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  /* background-color: #121212; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button,
a,
img {
  outline: 0;
}
.shadow-1 {
  -webkit-box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
button:focus,
a:focus,
img:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.help-section {
  padding-top: 10px;
}
.sing-property {
  position: relative;
  cursor: pointer;
}
.sing-property:hover::after {
  content: attr(titlel);
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 18px;
  border-radius: 5px;
  height: 30px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0px 50px;
}
.digit-group {
  margin: 24px auto 30px;
}
.digit-group input {
  width: 70px;
  height: auto;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #f6f6f6;
  line-height: 50px;
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  color: white;
  margin: 0 10px;
}
.digit-group input:focus-visible,
.login-card .fields-wrapper .field-control:focus-visible {
  outline: 0;
}
#reportCanvas {
  max-width: 75%;
  margin: 0px auto;
  border: 1px solid #f1f1f1;
  padding: 10px;
  box-shadow: 0px 10px 10px #f1f1f1;
}
.notification-outer-skin {
  position: relative;
}
.create-report-modal-inner .report-conclusion-factors{
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 15px;
}
.create-report-modal .report-evaulation-type{
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  margin-top: 12px;
  padding: 4px;
  background: #2d2d2d;
  border-radius: 2px;
}
.create-report-modal #add-new-test-form .report-evaulation-type button.btn{
  max-width: 100%;
}
.notification-outer-skin .closenotification {
  position: absolute;
  top: -10px;
  border-radius: 50%;
  z-index: 10;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  right: -10px;
  padding: 2px;
  font-size: 8px;
  left: auto;
  cursor: pointer;
}
button#error-messages {
  background: rgb(176 50 50 / 60%);
  opacity: 0;
  pointer-events: none;
  border-radius: 3px;
  max-width: 100%;
  font-family: "Neue Haas Grotesk Text Pro";
  width: 100%;
  border: none;
  margin: 10px 0px;
  font-style: normal;
  padding: 9px 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #f6f6f6;
}
span.collab > svg {
  fill: #fff;
  font-size: 25px;
}
img.app-main-logo {
  margin-right: 15px;
}
img.client-main-logo {
  margin-left: 15px;
}
.sidebarlist-wrapper {
  scrollbar-color: #6969dd #0b0909;
  scrollbar-width: thin;
}
form.segment-filters-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
form.segment-filters-form > div {
  margin-right: 2px;
  margin-bottom: 2px;
}
form.segment-filters-form > div select,
form.segment-filters-form > div input {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  height: 32px;
  outline: 0;
  border: 1px solid #dfe2e8;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
}
.manual-data-form-wrapper section label{
  position: initial;
  top: 0;
  left: 0;
  background-color: transparent;
  margin-bottom: 8px;
  color: #ebebeb;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}
#add-new-test-form .manual-data-form-wrapper .fields-wrapper.input-groups{
  column-gap: 1.3%;
  row-gap: 10px;
  flex-wrap: wrap;
}
.manual-data-form-wrapper .add-manu-data-button{
  text-align: right;
}
#add-new-test-form .manual-data-form-wrapper .add-manu-data-button button.btn{
  font-size: 12px;
  border-radius: 5px;
  padding: 4px 4px;
  max-width: 130px;
}

 #add-new-test-form .manual-data-form-wrapper .fields-wrapper.input-groups>div{
  max-width: 24%;
  width: 100%;
}
.manual-data-form-wrapper .fields-wrapper.input-groups input{
  max-width: 100%;
  padding: 6px 15px;
  border: none;
  background-color: #404040;
  border-radius: 5px;
  font-size: 14px;
  color: #f6f6f6;
}
.port-cards {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: calc(4% / 2);
}
.port-cards > div.portfolio-creater > .remove-portfolio{
  cursor: pointer;
  display: inline-flex;
  position: absolute;
  right: -5px;
  top: -5px;
  height: 18px;
  width: 18px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 100%;
  padding: 5px;
}
.port-cards.targeting > div.portfolio-creater {
  min-height: 300px;
}
.port-cards > div.portfolio-creater {
  max-width: 32%;
  width: 100%;
  padding: 15px 10px;
  position: relative;
  /* margin-bottom: 20px; */
  border-radius: 3px;
  background: var(--base-background-card);
}
.port-cards > div.portfolio-creater h5{
  color: #f6f6f6;
  font-weight: 400;
  font-size: 18px;
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 10px;
}
.port-cards > div.portfolio-creater p{
  font-weight: var(--base-font-weight);
  font-size: 15px;
  color: #ffffff;
  line-height: 20px;
  margin-bottom: 10px;
  display: inline-block;
  max-width: 100%;
  width: 100%;
}
.port-cards > div.portfolio-creater button{
  margin-left: auto;
  margin-right: 0px;
  margin-top: 5px;
  display: block;
}
.two-factor-form form,
.two-factor-form .error-message-wrapper {
  max-width: 599px;
  margin: 0px auto;
}
.two-factor-form h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 110%;
  text-align: center;
  margin-bottom: 15px;
  padding: 0px 20px;
  color: #f6f6f6;
  font-family: "Neue Haas Grotesk Text Pro";
}
.two-factor-form h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 0px 20px;
  color: #f6f6f6;
  font-family: "Neue Haas Grotesk Text Pro";
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.xls-sheet-wrapper{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}
.upload-btn-wrapper .btn {
  border: 1px solid gray;
  color: #003ed4;
  background-color: white;
  padding: 1px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}
form.segment-filters-form > div button {
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  border: none;
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
}
.created-filters {
  margin-top: 10px;
}
.created-filters > button {
  line-height: 0px;
  padding: 5px 10px;
  cursor: auto;
}
.created-filters > button > span.badge {
  cursor: pointer;
}
.sidebarlist-wrapper::-webkit-scrollbar,
.faq-wrapper > .faq-inner::-webkit-scrollbar {
  width: 10px;
}
.max-width100 {
  max-width: 100% !important;
}
.sidebarlist-wrapper::-webkit-scrollbar-track,
.faq-wrapper > .faq-inner::-webkit-scrollbar-track {
  background-color: #000;
}
.sidebarlist-wrapper::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 20px #ffffff;
}

/* ------------------- */
.form-wrapper-inner.scrubber::-webkit-scrollbar {
  width: 5px;
}
.form-wrapper-inner.scrubber::-webkit-scrollbar-track {
  background-color: #fff;
}
.form-wrapper-inner.scrubber::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 20px #000;
}
/* ---------------- */
ul.audience-insights-list {
  padding: 2px 0px 2px;
  margin-bottom: 0px;
}
ul.audience-insights-list > li {
  position: relative;
  padding-left: 22px;
}
span.colorSwatch {
  width: 15px;
  background-color: rgb(255, 215, 0);
  display: inline-block;
  height: 15px;
  border-radius: 50%;
  margin-right: 6px;
}
.authsidebar .offcanvas-body {
  max-height: 100vh;
}
.offcanvas-body .list-group > span.bg-light {
  opacity: 0.5;
}
.authsidebar .offcanvas-body .list-group > span[data-active="true"] {
  position: relative;
  background-color: #e9e9e9;
}
.offcanvas-body .list-group > span {
  cursor: pointer;
}
.authsidebar .offcanvas-body .list-group > span:hover::after,
.authsidebar .offcanvas-body .list-group > span[data-active="true"]::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -4px;
  left: auto;
  border-right: 4px solid #000;
}
button:disabled {
  opacity: 0.4;
}
ul.audience-insights-list > li::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  left: 0;
  background-image: url(/public/Images/greentick.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.kameleoon-fields {
  display: flex;
  /* align-items: center; */
}
.kameleoon-fields .fields-wrapper {
  width: 100%;
}
.kameleoon-fields > button {
  border: none;
  width: 30px;
  display: flex;
  background-color: transparent;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 12px;
}
ul.audience-insights-list > li:not(:last-child) {
  margin-bottom: 5px !important;
}
.custom-tooltip{
  color:rgb(233, 169, 52) !important;
  background-color: #404040 !important;
  font-size: medium;
  font-weight: 500;
}
.__react_component_tooltip.swtooltip {
  max-width: 320px;
  opacity: 1;
  overflow-wrap: anywhere;
  color: rgba(255, 255, 255, 0.80);
font-family: "SF Pro Display";
font-weight: 400;
}
.vaib {
  opacity: 0.5 !important;
}
.notification-toggler {
  position: relative;
}
.notification-toggler > span.notification-badge {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  right: 4px;
  top: 0px;
  height: 20px;
  font-size: 60%;
  border-radius: 50%;
  width: 20px;
  background: #2078f9;
}
span.toottip-span {
  margin-left: 4px;
  position: relative;
  top: -1px;
}
div.sw_custom_tour {
  padding: 20px 20px;
  border-radius: 5px;
  min-width: 278px;
}
div.sw_custom_tour .reactour__close {
  top: 8px;
  right: 8px;
}
div.sw_custom_tour > span {
  height: 2em;
  width: 2em;
  line-height: 0;
  justify-content: center;
  display: inline-flex;
  font-size: 0.9em;
  align-items: center;
  border-radius: 100%;
  top: -20px;
  left: -15px;
}
.faq-wrapper {
  align-items: center;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 999;
  justify-content: center;
  display: flex;
  bottom: 0;
  min-height: 100vh;
  top: 0;
  background-color: #ffffff70;
}
.faq-wrapper .close-faq {
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  font-size: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.faq-wrapper > .faq-inner {
  max-width: 700px;
  width: 100%;
  margin: 0px auto;
  background: #fff;
  overflow: auto;
  padding: 23px 40px;
  height: 90%;
  position: relative;
  border-radius: 5px;
  background: #222222;
  border: 1px solid #727272;
  border-radius: 10px;
}
.checkbox-wrapper-4 * {
  box-sizing: border-box;
}
.checkbox-wrapper-4 .cbx {
  margin-top: 0px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  display: inline-flex;
  font-style: normal;
  font-weight: var(--base-font-weight);
  font-size: 15px;
  line-height: 150%;
  text-transform: capitalize;
  white-space: nowrap;
  color: var(--base-white-color) !important;

}
.checkbox-wrapper-4 .cbx:not(:last-child) {
  margin-right: 6px;
}
.checkbox-wrapper-4 .cbx:hover {
  background: rgba(0,119,255,0.06);
}
.checkbox-wrapper-4 .cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:first-child {
  position: relative;
  width: 18px;
  display: inline-flex;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0,16,75,0.05);
  align-items: center;
  justify-content: center;
}
.checkbox-wrapper-4 .cbx span:first-child svg {
      /* position: absolute; */
  /* top: 3px; */
  /* left: 2px; */
  opacity: 0;
  position: relative;
  /* fill: none; */
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 0;
  /* stroke-linejoin: round; */
  /* stroke-dasharray: 16px; */
  /* stroke-dashoffset: 16px; */
  /* transition: all 0.3s ease; */
  /* transition-delay: 0.1s; */
  /* transform: translate3d(0, 0, 0); */
}
.checkbox-wrapper-4 .cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}
.checkbox-wrapper-4 .cbx:hover span:first-child {
  border-color: #07f;
}
.checkbox-wrapper-4 .inp-cbx {
  position: absolute;
  visibility: hidden;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
  background: #07f;
  border-color: #07f;
  animation: wave-4 0.4s ease;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
  opacity: 1;
}
.checkbox-wrapper-4 .inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
@media screen and (max-width: 640px) {
  .checkbox-wrapper-4 .cbx {
    width: 100%;
    display: inline-block;
  }
}
@-moz-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

.faq-wrapper > .faq-inner::-webkit-scrollbar {
  border-radius: 10px;
  width: 4px;
}
.faq-wrapper > .faq-inner::-webkit-scrollbar-thumb {
  background-color: #727272;
  width: 4px;
  border-radius: 5px;
}
.faq-wrapper > .faq-inner::-webkit-scrollbar-track {
  background-color: #404040;
  border-radius: 5px;
}

.faq-wrapper .faq-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #b9b9b9;
  padding-bottom: 18px;
}
.faq-wrapper .faq-heading svg {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.faq-wrapper .faq-heading svg path {
  fill: #fff;
}
.faq-wrapper form {
  position: relative;
  margin: 30px 0px;
  margin-bottom: 10px;
}
.faq-wrapper form input {
  background: #404040;
  border-radius: 5px;
  width: 100%;
  border: none;
  height: 46px;
  padding: 0px 14px;
  color: #fff;
  padding-left: 44px;
}
.faq-wrapper form input::before {
  content: "";
  background-image: url("data:image/svg+xml, %3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M29.0001 27.586L21.4481 20.034C23.2629 17.8553 24.1679 15.0608 23.9748 12.2319C23.7817 9.40296 22.5054 6.75738 20.4114 4.84551C18.3173 2.93363 15.5669 1.90267 12.7321 1.96708C9.89729 2.03149 7.19647 3.18632 5.19146 5.19133C3.18644 7.19635 2.03161 9.89717 1.9672 12.732C1.90279 15.5667 2.93376 18.3172 4.84563 20.4112C6.75751 22.5052 9.40309 23.7816 12.232 23.9747C15.061 24.1678 17.8554 23.2628 20.0341 21.448L27.5861 29L29.0001 27.586ZM4.00012 13C4.00012 11.22 4.52796 9.4799 5.51689 7.99986C6.50582 6.51982 7.91143 5.36627 9.55596 4.68508C11.2005 4.00389 13.0101 3.82566 14.7559 4.17293C16.5018 4.52019 18.1054 5.37736 19.3641 6.63603C20.6227 7.89471 21.4799 9.49835 21.8272 11.2442C22.1745 12.99 21.9962 14.7996 21.315 16.4441C20.6338 18.0887 19.4803 19.4943 18.0002 20.4832C16.5202 21.4722 14.7801 22 13.0001 22C10.614 21.9973 8.32633 21.0483 6.63908 19.361C4.95182 17.6738 4.00276 15.3861 4.00012 13Z' fill='%23727272' /%3E%3C/svg%3E");
}
.faq-hero h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 110%;
  color: #ffffff;
  text-align: left;
  margin-bottom: 0px;
}
/* .faq-wrapper > .faq-inner .faq-hero{
  height: 200px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .faq-wrapper > .faq-inner .faq-search{
  max-width: 60%;
  width: 100%;
} */
/* .faq-wrapper > .faq-inner .faq-hero input{
  max-width: 100%;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: none;
  padding: 0px 20px;
  font-weight: 300;
} */
.faq-wrapper > .faq-question-inner {
  margin-bottom: 12px;
}
/* .faq-question-wrapper {
  padding: 5px 10px;
} */
.faq-wrapper > .faq-inner .description {
  /* margin-bottom: 4px;
  margin-top: 8px; */
  color: #ebebeb;
  border-bottom: 0.5px solid #b9b9b9;
}
.faq-wrapper > .faq-inner .description-wrapper {
  padding: 36px 16px;
}
.faq-wrapper > .faq-inner .description * {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.faq-wrapper > .faq-inner .faq-results {
  max-width: 100%;
}
.faq-wrapper .faq-question-inner .heading {
  cursor: pointer;
  padding: 20px 0px;
  border-radius: 0px;
  border-bottom: 0.5px solid #b9b9b9;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown-menu.topbar-dropdown{
  padding: 1rem 1rem;
  background-color: #121212;
  flex-direction: column;
  border: 1px solid #ffffff57;
  min-width: 200px;
}
.dropdown-menu.topbar-dropdown li:not(:last-child){
 margin-bottom: 10px;
}
.dropdown-menu.topbar-dropdown li a .icon{
  margin: 0px 0px;
}
.account-details-inner .dropdown .dropdown-toggle::after{
  display: none;
}
.account-details-inner .dropdown > a{
  color: #fff;
  text-decoration: none;
}
.dropdown-menu.topbar-dropdown li a{
  color: #fff;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  column-gap: 10px;
  line-height: 20px;
}
/* .faq-wrapper .faq-question-inner .heading::after {
  content: "";
  position: relative;
  height: 10px;
  width: 19px;
  min-width: 19px;
  display: inline-flex;
  margin-left: 5px;
  background-image: url("/public/Images/selectarrow.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
} */
.faq-wrapper .faq-question-inner .heading.active-heading::after {
  transform: rotate(-90deg);
}
.faq-wrapper .faq-question-inner .heading h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 0px;
  color: #ffffff;
  max-width: 80%;
}

.faq-wrapper
  .fields-wrapper
  .form-check
  > label {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.faq-wrapper .fields-wrapper .form-check-input[type="radio"] {
  padding: 0;
  margin: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #f6f6f6;
  cursor: pointer;
  box-shadow: none;
}

.faq-wrapper > .survey-inner {
  height: 50% !important;
}

.faq-wrapper .survey-question {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 10px;
  color: #ffffff;
  max-width: 80%;
}

.survey-submit-btn {
  margin-top: 30px !important;
  float: right;
}

.survey-error-msg {
  color: rgb(176 50 50);
  text-align: center;
}

.faq-wrapper .radio-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.faq-wrapper .radio-group-wrapper {
  display: flex;
  align-items: center;
}

.faq-wrapper .radio-wrapper {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.faq-wrapper .radio-wrapper .radio-labels {
  font-weight: 400;
  font-size: 15px;
  line-height: 110%;
  text-transform: capitalize;
  color: #f6f6f6;
  margin-bottom: 0px;
  background: none;
  position: relative;
  top: 0;
}

.accordion__content {
  /* background-color: white; */
  overflow: auto;
  transition: max-height 0.4s ease;
}
.accordion__content::-webkit-scrollbar {
  display: none;
}
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}
.rotate {
  transform: rotate(-90deg);
}

span.toottip-span > svg {
  font-size: 13px;
  cursor: pointer;
}
.csv-import-inner .upload-sheet {
  padding: 10px 0px;
  background: #f1f1f1;
  max-width: 400px;
  margin: 0px auto;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.csv-import-inner .upload-sheet svg {
  font-size: 50px;
  cursor: pointer;
}
.csv-import-inner .upload-sheet svg:hover {
  fill: green;
}
.csv-import-inner .upload-sheet label {
  font-size: 14px;
  font-weight: 500;
}
.help-section > span {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}
.help-section > span:hover {
  opacity: 0.5;
}
.properties > .sing-property:not(:last-child) {
  margin-bottom: 6px;
}
.navbar.authnavbar {
  background-color: #070707;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
}
.navbar.authnavbar .toggler {
  display: inline-flex;
  align-items: center;
}
.navbar.authnavbar .toggler img {
  max-width: 100%;
  max-height: 28px;
  /* margin-top: 6px; */
}
.full-width .navbar.authnavbar {
  max-width: 100%;
  margin-left: 0px;
}
.authnavbar .navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
}
.ReactTags__selected {
  margin-top: 10px;
}
.segment-wrapper .segment-inner .segment-heading {
  width: 100%;
  max-width: 210px;
  font-size: 16px;
  font-weight: 500;
}

.reporting-module-wrapper h3 {
  font-size: 16px;
  margin-bottom: 5px;
}
.reporting-module-wrapper {
  padding: 10px 0px;
  position: relative;
  border-radius: 3px;
}
.reporting-module-wrapper select {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  height: 42px;
  outline: 0;
  border: 1px solid #dfe2e8;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
}
button.page-link:disabled {
  background-color: #f1f1f1;
  color: #000;
  opacity: 0.5;
}
.segment-rules .secondary-selects > select {
  max-width: 24%;
  margin-right: 0.5%;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  height: 32px;
  outline: 0;
  border: 1px solid #dfe2e8;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
}
.create-request-wrapper .submit-request {
  padding: 15px 0px;
}
/* .create-request-wrapper .submit-request button {
  border: none;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 242px;
  width: 100%;
  background-color: green;
  color: #fff;
  border-radius: 5px;
  margin-left: auto;
} */
.segment-rules,
.segment-rules .test-lists-btns {
  position: relative;
  width: 100%;
}
.reporting-module-inner,
.segment-rules .test-lists-btns {
  display: inline-flex;
  flex-wrap: wrap;
  border-radius: 5px;
}
.segment-rules .test-lists-btns {
  background-color: #f1f1f1;
  padding: 5px 10px;
}
.reporting-module-inner button .test-stat {
  height: 12px;
  display: flex;
  width: 12px;
  border-radius: 5px;
}
.reporting-module-inner button,
.segment-rules .test-lists-btns button {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  height: 32px;
  outline: 0;
  border: none;
  box-sizing: border-box;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 5px;
  position: relative;
}
.logo-loader-wrapper {
  height: 100vh;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.logo-loader-wrapper img {
  max-width: 80%;
  margin: 0px auto;
  display: block;
}
.segment-rules .test-lists-btns button svg {
  position: absolute;
  fill: green;
  top: -8px;
  right: -4px;
  font-size: 20px;
}
.create-report-modal-inner > .card,
.add-sheet-link-modal .card {
  background-color: #222222;
  border: 1px solid #727272;
  border-radius: 10px;
}
.create-report-modal-inner > .card .card-body,
.add-sheet-link-modal .card .card-body {
  padding: 24px 40px;
}
.create-report-cta {
  position: fixed;
  right: 0;
  top: auto;
  bottom: 10%;
  z-index: 2;
}
.create-report-cta button {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none;
  min-width: 20px;
  transition: all 0.2s ease-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  background-color: #000000;
  color: #fff;
  border-radius: 5px 0px 0px 5px;
  position: relative;
}
.create-report-cta button span {
  display: none;
  margin-left: 3px;
}
.create-report-cta button:hover {
  min-width: 165px;
}
.create-report-cta button:hover span {
  display: inline-block;
}
.reporting-module-inner button {
  background-color: #000;
  color: #fff;
  position: relative;
}
.reporting-module-inner button.active-test:after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  height: 20px;
  z-index: -1;
  margin: auto;
  width: 20px;
  background: black;
  transform: rotate(45deg);
}
.segment-rules .test-lists-btns button {
  background-color: #fff;
  color: #000;
}
.segment-wrapper .segment-inner {
  display: flex;
  align-items: center;
}
.client-dashboard-tabs .nav-link.active {
  background: #f1f1f1;
  border-bottom: 2px solid #000;
}
/* TIMELIME */
/* The actual timeline (the vertical ruler) */
.main-timeline-2 {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-2::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: #f1f1f1;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-2 {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-2::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -11px;
  background-color: #f1f1f1;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}
ul.test-overview-list {
  list-style: none;
  padding-left: 0px;
}

ul.test-overview-list > li {
  position: relative;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 20px;
  display: flex;
  margin-bottom: 0px;
  font-weight: 300;
  align-items: center;
  justify-content: space-between;
}
ul.test-overview-list > li:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}
ul.test-overview-list > li::before {
  content: "*";
  display: inline-flex;
  position: absolute;
  left: 0;
}

/* Place the container to the left */
.main-timeline-2 > .timeline-2:nth-child(odd) {
  padding: 0px 40px 20px 0px;
  left: 0;
}
.main-timeline-2 > .timeline-2:nth-child(even) {
  padding: 0px 0px 20px 40px;
  left: 50%;
}
.main-timeline-2 > .timeline-2 p {
  font-size: 14px;
}
.left-2 {
  /* padding: 0px 40px 20px 0px;
  left: 0; */
}

/* Place the container to the right */
.right-2 {
  /* padding: 0px 0px 20px 40px;
  left: 50%; */
}

.pre-cal-cancel {
  border: none;
  background: none;
  padding: 10px;
  color: gray;
}

.pre-cal-apply {
  border: none;
  background: none;
  padding: 10px;
  color: blue;
}

.pre-button-box {
  width: 100%;
  border-top: 1px solid gray;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.pre-checkbox-container {
  display: flex;
  align-items: center;
}

.pre-condition-container {
  color: #fff;
  border: 1px solid gray;
  border-radius: 5px;
  border-left: 5px solid;
  display: flex;
  padding: 10px 10px;
  font-size: 16px;
  /* cursor: pointer; */
}
.pre-condition-container.addNew{
  max-width: fit-content;
  cursor: pointer;
}
.event-or-page-wrapper .pre-condition-container.addNew{
  max-width: fit-content;
  display: flex;
  margin-left: auto;
  margin-right: 0px;
  cursor: pointer;
}

.pre-condition-sub-container {
  color: #fff;
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
  /* margin: 16px 0; */
  padding: 10px;
  /* height: 60px; */
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
}
.pre-condition-sub-container .operat{
  font-size: 70%;
}
.pre-condition-sub-container .operatval{
  font-weight: 600;
}
.pre-condition-sub-container svg{
  cursor: pointer;
}
.event-or-page-wrapper{
  display: flex;
  /* align-items: center; */
  margin: 10px 0px;
  column-gap: 15px;
  justify-content: space-between;
}
.event-or-page-wrapper > div:first-child{
  min-width: 20%;
}
.event-or-page-wrapper > div:last-child{
  width: 100%;
  max-width: 100%;
}
.event-or-page-wrapper > div:last-child > div:not(:last-child){
  margin-bottom: 10px;
}
.pre-condition-sub-container > .acc-newselectwrapper > .acc-newselect__control {
  height: 60px;
  border-top: 0;
  border-bottom: 0;
}

/* Add arrows to the left container (pointing right) */
.main-timeline-2 > .timeline-2:nth-child(odd)::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.main-timeline-2 > .timeline-2:nth-child(even)::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.main-timeline-2 > .timeline-2:nth-child(even)::after {
  left: -14px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .main-timeline-2::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-2 {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-2::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .main-timeline-2 > .timeline-2:nth-child(even)::after,
  .main-timeline-2 > .timeline-2:nth-child(odd)::after {
    left: 18px;
  }

  .main-timeline-2 > .timeline-2:nth-child(even)::before {
    right: auto;
  }

  /* Make all right containers behave like the left ones */
  .main-timeline-2 > .timeline-2:nth-child(even) {
    left: 0%;
  }
}
/* TIMELINE */
.green-shadow {
  box-shadow: 0 0.2rem 0.2rem #4caf50 !important;
}
.logo-wrapper a img {
  object-fit: contain;
  max-width: 90%;
  width: auto;
  padding: 5px 0px;
  margin: 0px auto;
  display: block;
}
.authnavbar button svg {
  max-width: 30px;
  height: 30px;
}
.authnavbar button path {
  stroke: #fff;
}
.authlayout-inner {
  display: flex;
  max-width: 100vw;
}

.authlayout-inner .authsidebar {
  width: 100%;
  max-width: 315px;
  position: fixed;
  top: calc(95px);
  bottom: 0;
  left: 0;
  background: #121212;
}
.full-width .authlayout-inner .authsidebar,
.full-width .setting-bar-left {
  max-width: 0%;
  overflow: hidden;
}
.authlayout-inner .authcontent {
  width: 100%;
  max-width: calc(100% - 315px);
  margin-left: calc(315px);
  padding: 30px 20px;
}
.full-width .authlayout-inner .authcontent {
  max-width: 100%;
  margin-left: 0px;
}
.authcontent-padding {
  padding-left: 15px;
  padding-top: 15px;
  padding-right: 15px;
}
.authsidebar-inner > .logo-wrapper {
  padding: 5px 0px;
}
.authsidebar-inner {
  overflow: auto;
}
.sidebarlist-wrapper {
  padding: 10px 14px;
  overflow: auto;
  max-height: calc(100vh - 150px);
}
.sidebarlist-wrapper #subitem {
  padding-left: 10px;
  display: none;
}
.sidebarlist-wrapper .dropactive + #subitem {
  display: block;
}
.sidebarlist-wrapper #subitem a {
  min-height: 36px;
  margin-bottom: 10px;
}
.sidebarlist-wrapper #subitem a.active {
  background: linear-gradient(0deg, #dadada, #dadada), #dadada;
}
.sidebarlist-wrapper a {
  text-decoration: none;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background: #f8f8f8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  min-height: 45px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 15px;
  color: #000000;
}
.sidebarlist-wrapper a.active,
.sidebarlist-wrapper a.dropactive {
  background: #c8d7fd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.sidebarlist-wrapper a.dropactive > svg {
  transform: rotate(90deg);
}
/* ADMIN CSS */
.admin-dashboard-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.admin-dashboard-inner > .card {
  max-width: 48%;
  width: 100%;
  margin: 8px 0.5%;
  border-radius: 5px;
  cursor: pointer;
}
.admin-dashboard-inner > .card h5 {
  font-size: 16px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-dashboard-inner > .card h5 span {
  background-color: #2078f9;
  padding: 5px;
  border-radius: 100px;
  color: #fff;
  font-size: 10px;
  top: 4px;
  min-width: 22px;
  right: 4px;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  margin-left: 10px;
}
.admin-dashboard-inner > .card h6 {
  font-size: 32px;
  margin-bottom: 0px !important;
}
.admin-dashboard-inner > .card svg {
  font-size: 30px;
  margin-bottom: 10px;
}
.form-wrapper,
.create-report-modal {
  position: fixed;
  margin-left: auto;
  /* max-width: calc(100% - 315px); */
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #607d8b36;
  z-index: 2;
}
.form-wrapper.scrubber-wrapper {
  max-width: 100%;
}
.scrub > div {
  min-height: 500px;
}
.full-width .form-wrapper,
.full-width .create-report-modal {
  max-width: 100%;
}
.custom-preloader {
  margin: 25px auto;
  max-width: 100%;
  width: 100%;
}
.announcements-wrapper {
  margin-bottom: 10px;
}
.announcements-wrapper > .announcements-inner {
  padding: 10px;
  background: red;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.announcements-wrapper > .announcements-inner > .announcement-text {
  max-width: 80%;
  width: 100%;
}
.announcements-wrapper > .announcements-inner p {
  color: #fff;
  margin-bottom: 0px;
  font-weight: normal;
}
.announcements-wrapper .announcement-full-text {
  background-color: #f83f37fc;
  padding: 5px;
  border-radius: 0px 0px 4px 4px;
  display: none;
  border-top: 2px solid #000;
}
.form-wrapper .card-body > h3,
.create-report-modal-inner .card-body > h3 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 0px;
}
.create-report-modal-inner .card-body > h3,
.form-wrapper.add-sheet-link-modal .card-body > h3 {
  color: #f6f6f6;
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #727272;
  font-weight: 400;
  font-size: 23px;
  line-height: 110%;
}
.react-emoji {
  border: none;
  border-radius: 5px;
  margin-bottom: 25px;
  margin-top: 25px;
}
.fields-wrapper .react-input-emoji--container {
  margin: 0px;
  background-color: #404040;
  border: none;
  border-radius: 5px;
}
.react-input-emoji--placeholder {
  font-size: 14px;
}
.react-input-emoji--input {
  color: #f6f6f6;
  font-size: 14px;
}
.form-wrapper .form-wrapper-inner,
.create-report-modal-inner {
  max-width: 500px;
  width: 100%;
  max-height: 95vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: auto;
}
.form-wrapper .form-wrapper-inner.snippets-preview{
  max-width: 90%;
}
.create-report-modal-inner,
.add-sheet-link-modal > .form-wrapper-inner {
  max-width: 600px;
  box-shadow: none;
}
.add-sheet-link-modal .acc-newselect__control--menu-is-open .acc-newselect__input-container {
  padding-left: 0px;

}
.segment-graph-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}
.segment-graph-wrapper > .segment-graph-inner {
  max-width: 50%;
  padding: 10px 15px;
  width: 100%;
}
.rmsc
  .dropdown-heading
  .dropdown-heading-value
  > span
  > span.mr-1:not(:last-child) {
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #141414;
}
.form-wrapper .form-wrapper-inner.addtestform {
  max-width: 630px;
}
.addtestform h5 {
  margin-bottom: 15px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #003ed4;
}
.form-wrapper .form-wrapper-inner.scrubber {
  /* max-width: 92%; */
  max-height: 90vh;
  max-width: 768px;
  /* padding: px 20px; */
  background-color: #000;
  border-radius: 15px;
  position: relative;
}
.form-wrapper .form-wrapper-inner.scrubber button.close-modal {
  top: 0px;
  right: 0;
  background: #000;
  z-index: 6;
  width: 25px;
  height: 25px;
  border-radius: 2px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-wrapper .form-wrapper-inner.scrubber button.close-modal svg {
  width: 10px;
  height: 10px;
}
.scrubber-handle-inner button {
  position: relative;
  height: 25px;
  width: 25px;
}
.scrub:not(.dragged) .scrubber-handle-inner button::after {
  border: 1px solid #000;
  content: "Slide to Compare";
  font-weight: 400;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 28px;
  width: 150px;
  background-color: #fff;
  border-radius: 3px;
  left: 50%;
  transform: translate(-50%);
  top: -30px;
}
.calculated-boxes {
  padding: 5px 5px;
}
.experiment-data .calculated-boxes {
  padding: 0px;
  padding-bottom: 50px;
}
.experiment-data .calculated-boxes {
  padding: 0px;
  padding-bottom: 50px;
}
.calculated-boxes-inner {
  display: flex;
  align-items: normal;
  justify-content: normal;
  flex-wrap: wrap;
}
.calculated-boxes-inner > div {
  background-color: #f1f1f1;
  width: 100%;
  max-width: 16%;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  min-height: 80px;
  justify-content: center;
  flex-direction: column;
  margin: 2px 0.33%;
  flex: 1 1 33%;
  max-width: 33%;
  width: 100%;
}
.report-details-wrapper {
  position: fixed;
  margin-left: auto;
  max-width: calc(100% - 350px);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #607d8b36;
  z-index: 2;
}
.report-details-inner {
  max-width: 1024px;
  width: 100%;
  max-height: 95vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: auto;
}
.report-details-wrapper.notification-report-details .report-details-inner {
  max-width: 85%;
  max-height: 100vh;
}
.authy {
  max-width: 375px;
  width: 100%;
  padding: 24px 24px;
  border-radius: 5px;
}
.visual-box-inner h3 {
  font-size: 16px;
  margin-bottom: 10px;
}
.calculated-boxes-inner > div label {
  font-size: 12px;
  margin-bottom: 5px;
  padding-bottom: 3px;
  border-bottom: 1px solid #23316d;
}
.calculated-boxes-inner > div .value {
  font-size: 16px;
}
.login-wrapper {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #121212;
}
.login-wrapper .login-inner {
  /* padding: 25px; */
  /* border-radius: 5px; */
  max-width: 474px;
  width: 100%;
  display: flex;
}
.two-factor-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.two-factor-wrapper form input {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0px 15px;
  height: 42px;
  outline: 0;
  border: 1px solid #dfe2e8;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
}
.test-lists-btns button > span.test-stat {
  height: 10px;
  width: 10px;
  display: inline-flex;
  border-radius: 100%;
}
.test-lists-btns button {
  justify-content: center;
  display: inline-flex;
  align-items: center;
  outline: 0;
}
.result-boxes {
  width: 100%;
}
.result-boxes .result-box-inner {
  width: 100%;
  display: flex;
  margin-bottom: 4px;
  margin-top: 4px;
  padding: 0px;
  justify-content: space-between;
  /* border-bottom: 1px solid #f1f1f1; */
}
.client-overall-results-wrapper {
  border-radius: 5px;
  padding: 10px 25px;
  margin-bottom: 25px;
}
.client-revenue-inner > .revenue-box label {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}
.client-revenue-inner > .revenue-box label span {
  font-size: 14px;
  opacity: 0.6;
}

.client-revenue-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}
.client-revenue-inner > .revenue-box {
  text-align: center;
  max-width: 255px;
  width: 100%;
  margin: 0px 15px;
  padding: 10px 10px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.client-overall-heading h3 {
  text-align: center;
}
.client-revenue-inner > .revenue-box h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #00800d;
  margin: 0px 0px;
  margin-top: 4px;
}
.client-overall-results-wrapper .overall-box {
  /* margin-top: 25px; */
  align-items: center;
  display: flex;
}
.client-overall-results-wrapper .overall-box .left .suc-rate p {
  margin-bottom: 10px;
}
.client-overall-results-wrapper .overall-box .left .suc-rate p.numb {
  font-size: 45px;
  font-weight: 400;
}
.client-overall-results-wrapper .overall-box .left {
  max-width: 30%;
  padding: 30px;
  text-align: center;
  width: 100%;
}
.client-overall-results-wrapper .overall-box .Right .our-stats {
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  flex-wrap: wrap;
}
.client-overall-results-wrapper .overall-box .Right .our-stats > div {
  max-width: 48%;
  width: 100%;
  margin: 15px auto;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
}
.client-overall-results-wrapper .overall-box .Right .our-stats > div > p {
  margin-bottom: 5px;
  position: relative;
}
.client-overall-results-wrapper
  .overall-box
  .Right
  .our-stats
  > div
  > p::before {
  content: "";
  max-width: 10%;
  bottom: 0;
  margin: auto;
  right: 0;
  left: 0;
  width: 100%;
  position: absolute;
  border-bottom: 1px solid #f1f1f1;
}
.client-overall-results-wrapper .overall-box .Right .our-stats > div > span {
  font-weight: 400;
  font-size: 24px;
  line-height: 22px;
  color: #000000;
}
.client-overall-results-wrapper .overall-box .Right {
  max-width: 70%;
  width: 100%;
  padding: 15px 82px;
  text-align: center;
}
.result-boxes .result-box {
  max-width: 30%;
  width: 100%;
}
.result-boxes .result-box-inner > div:nth-child(1) {
  font-weight: 300;
  max-width: 70%;
}
.test-lists-btns button.active {
  opacity: 0.8;
}
.test-lists-btns button:focus {
  box-shadow: none !important;
}
.login-wrapper .login-card {
  max-width: 100%;
  width: 100%;
}
.login-wrapper
  .login-card
  form
  > div.fields-wrapper
  input
  .login-wrapper
  .login-card
  form
  > div.fields-wrapper
  select {
  max-width: 100%;
  width: 100%;
  display: flex;
  font-weight: 300;
  align-items: center;
  margin-bottom: 15px;
  padding: 0px 15px;
  height: 42px;
  outline: 0;
  border: 1px solid #dfe2e8;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
}
.acc-newselect__menu {
  z-index: 3 !important;
}
.check-box-buttons-wrapper {
  padding: 15px 0px;
  border: 1px solid #dfe2e8;
  border-radius: 5px;
  padding: 10px 10px;
}
.check-box-buttons-wrapper > button {
  border: 1px solid #000;
  background-color: transparent;
  padding: 5px 15px;
  border-radius: 5px;
  margin-right: 10px;
}
.check-box-buttons-wrapper > button.active {
  background-color: #000;
  color: #fff;
}
.login-wrapper .login-card form > div.fields-wrapper {
  width: 100%;
  max-width: 100%;
}
.form-wrapper form,
.create-report-modal-inner form {
  margin-top: 15px;
}
.fields-wrapper .active-select .acc-newselect__control {
  border: 1px solid green;
}
.fields-parent-50 > .fields-wrapper {
  max-width: 48%;
  width: 100%;
}
.fields-wrapper.fields-wrapper-checkbox {
  display: flex;
  align-items: center;
}
.fields-wrapper.fields-wrapper-checkbox > input {
  position: absolute;
  left: 0;
}
.fields-wrapper.fields-wrapper-checkbox > label {
  position: relative;
  margin-left: 18px;
  left: 0;
  top: 0;
  bottom: 0;
}
/* * > .fields-wrapper:not(:last-child), * > .fields-wrapper-group:not(:last-child){
  margin-bottom: 28px;
} */
.addcontrolvariantkameleoon {
  text-align: right;
  padding: 10px 0px;
}
.addcontrolvariantkameleoon span {
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
  background-color: #003ED4;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
}


.authlayout-inner .fields-wrapper .field-control-date {
  max-width: 100%;
  width: 100%;
  display: inline;
  align-items: center;
  padding: 0px 10px;
  height: 42px;
  outline: 0;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 300;
  color: #f6f6f6 !important;
}
.fields-wrapper .field-control-date {
  padding: 6px 15px;
  border: none;
  background-color: #404040 !important;
  font-size: 14px;
  color: #f6f6f6 !important;
}
.fields-wrapper .field-control-date:disabled {
  padding: 6px 15px;
  border: none;
  background-color: #595757 !important;
  font-size: 14px;
  color: #c6c0c0 !important;
}
.authlayout-inner .fields-wrapper .field-control {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  height: 42px;
  outline: 0;
  border: 1px solid #dfe2e8;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  font-weight: 300;
}
 .react-tagsinput {
    border: none;
    background-color: transparent;
   border: none;
}
.react-tagsinput .react-tagsinput-tag{
 background-color: #003ed4;
 color: #fff;
 border: none;
}
.react-tagsinput .react-tagsinput-tag a.react-tagsinput-remove{
  color: red;
  text-decoration: none;
}
.authlayout-inner .fields-wrapper.theme-black .field-control {
  background-color: #121212;
  color: #fff;
  border-color: #404040;
}
.authlayout-inner .fields-wrapper textarea.field-control {
  padding: 5px 10px;
  height: 100%;
}

.authlayout-inner .kameleoon-fields {
  margin-bottom: 15px;
}
.authlayout-inner .kameleoon-fields .fields-wrapper {
  margin-bottom: 0px;
}
.authlayout-inner .fields-wrapper {
  margin-bottom: 15px;
}
.authlayout-inner .fields-wrapper.filters-checkbox p {
  color: #fff;
  margin-bottom: 0px;
  display: inline-flex;
  column-gap: 10px;
  cursor: pointer;
}
.create-report-modal-inner .authlayout-inner .fields-wrapper:last-child {
  margin-bottom: 0px;
}

.login-card .field-control {
  font-family: "Neue Haas Grotesk Text Pro", "sans-serif";
}
.login-card .fields-wrapper .field-control {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #f6f6f6;
  border-radius: 0px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.05em;
  min-height: 42px;
  color: #f6f6f6;
  width: 100%;
}
.login-card .fields-wrapper:not(:last-child),
.login-card .fields-wrapper-group:not(:last-child) {
  margin-bottom: 28px;
}
.login-card .fields-wrapper-group .field-control {
  background-color: transparent;
  border: none;
  border-radius: 0px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: #f6f6f6;
}
.login-card .fields-wrapper-group > div.border-bottom-custom {
  border-bottom: 1px solid #f6f6f6;
}
.login-card .fields-wrapper .field-control:focus,
.login-card .fields-wrapper-group > div.border-bottom-custom.focus {
  border-bottom: 1px solid #003ed4;
}
.login-card .forgot-password {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
}
.login-card .forgot-password a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: right;
  text-decoration-line: underline;
  font-family: "Neue Haas Grotesk Text Pro", "sans-serif";
  color: #727272;
}
.login-card .fields-wrapper-group button {
  background-color: transparent;
}
.login-card .fields-wrapper-group button svg {
  width: 24px;
  height: 24px;
}
.login-card form .fields-wrapper > button {
  font-family: "Neue Haas Grotesk Text Pro", sans-serif;
  width: 100%;
  max-width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  text-transform: capitalize;
  background: #003ed4;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
.login-card form .fields-wrapper > button:not(:disabled):hover {
  opacity: 0.8;
}
.login-card form .fields-wrapper > button:disabled {
  background: #404040;
  border-radius: 5px;
  color: #121212;
}
.authy .logo-wrapper,
.login-header .logo-wrapper {
  margin-bottom: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-navigator ul {
  display: flex;
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 25px;
}
.custom-navigator ul > li:not(:last-child) {
  margin-right: 10px;
}
.custom-navigator ul > li button {
  border: none;
  background-color: #f9f9f9;
  border-radius: 5px;
  display: flex;

  align-items: center;
}
.custom-navigator ul > li button > span {
  font-size: 12px;
}
.authy .logo-wrapper > img,
.client-overall-results-inner > img {
  max-width: 42%;
  width: 100%;
  max-height: 30px;
  margin: 0px 3%;
  object-fit: contain;
  object-position: left;
}
.login-header .logo-wrapper > img {
  max-width: 200px;
  max-height: 200px;
}
.fields-wrapper .field-control.valid {
  /* border:1px solid #b7e183; */
  background-image: url(/public/Images/SVGS/tick.svg) !important;
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 98% 50%;
  cursor: auto;
}
.not-authorized-wrapper,
.not-found-wrapper {
  position: fixed;
  background-color: #000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-authorized-wrapper .not-authorized-inner,
.not-found-wrapper .not-found-inner {
  text-align: center;
}
.not-authorized-wrapper .not-authorized-inner svg,
.not-authorized-inner svg {
  fill: #fff;
  font-size: 25px;
}
.not-authorized-wrapper h2,
.not-found-wrapper h2 {
  color: #fff;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 100;
}
form .list-group .rmve {
  position: absolute;
  right: 10px;
  top: 2;
}
form .list-group .rmve > svg {
  cursor: pointer;
  width: 12px;
  height: 12px;
}
form .fields-wrapper-group input {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  /* padding: 0px 15px; */
  height: 42px;
  outline: 0;
  border: 1px solid #dfe2e8;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px 0px 0px 5px;
  font-weight: 300;
}
form .fields-wrapper-group input#learningText {
  padding: 0px 15px;
}
form .fields-wrapper-group input#learningText + button {
  /* padding: 0px 5px;
  font-size: 11px;
  width: 55px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: #003ed4; */
}
form .fields-wrapper.success-creteria {
  display: flex;
  align-items: center;
  /* margin-top: 15px; */
  margin: 15px 0px;
  justify-content: space-between;
}
.no-access-internal {
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: center;
}
.no-access-internal > div {
  text-align: center;
}
form .fields-wrapper.success-creteria > button {
  max-width: 32%;
  min-width: auto;
  width: 100%;
  position: relative;
}
form .fields-wrapper.success-creteria > button svg {
  position: absolute;
  top: 4px;
  right: 8px;
}
form .fields-wrapper.success-creteria > button.winner {
  background-color: green;
}
form .fields-wrapper.success-creteria > button.neutral {
  background-color: #f1f1f1;
  color: #000;
}
form .fields-wrapper.success-creteria > button.no_uplift {
  background-color: red;
}
.leanings-preview {
  margin-top: 15px;
  margin-bottom: 15px;
}
.leanings-preview svg {
  fill: #f6f6f6;
}
.leanings-preview ul {
  margin-left: 0px;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.leanings-preview ul > li {
  position: relative;
  padding: 10px 0px;
  border-bottom: 1px solid #fff;
  column-gap: 5px;
}
.leanings-preview ul > li > svg {
  /* position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto; */
}
.leanings-preview ul > li span {
  /* display: inline-flex;
  width: 100%;
  background: #404040;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 3px; */
  color: #f6f6f6;
  line-height: 20px;
}
.leanings-preview ul > li span > svg {
  /* background-color: #000; */
  border-radius: 50%;
  fill: var(--color-neon);
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.form-wrapper .fields-wrapper textarea,
.create-report-modal-inner .fields-wrapper textarea {
  width: 100%;
  padding: 10px 10px;
  outline: 0;
  border: 1px solid #dfe2e8;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  font-weight: 300;
}
.form-wrapper .form-wrapper-inner form .multi-select {
  margin-bottom: 15px;
}
.create-report-modal-inner form input#learningText,
.create-report-modal-inner #segmentName,
.add-sheet-link-modal .acc-newselect__control,
.add-sheet-link-modal .fields-wrapper .field-control {
  padding: 0px 15px;
  border: none;
  background-color: #404040;
  border-radius: 5px;
  font-size: 14px;
  color: #f6f6f6;
}
.add-sheet-link-modal button.close-modal {
  right: 26px;
}
.add-sheet-link-modal textarea.field-control {
  height: fit-content;
}
.add-sheet-link-modal .acc-newselect__value-container {
  padding: 0;
}
.add-sheet-link-modal .acc-newselect__input-container {
  color: #f1f1f1;
}
.add-sheet-link-modal .acc-newselect__indicator-separator {
  background-color: #222222;
}
.add-sheet-link-modal .acc-newselect__indicator {
  height: 7px;
  width: 13px;
  margin-left: 15px;
}
.add-sheet-link-modal .acc-newselect__menu {
  background-color: transparent;
}
.add-sheet-link-modal .acc-newselect__menu-list .acc-newselect__option {
  padding: 10px;
  font-size: 13px;
}
/* :is(.create-report-modal-inner) form input#learningText , #segmentName{
  padding: 0px 15px;
  border: none;
  background-color: #404040;
  border-radius: 5px;
  font-size: 14px;
  color: #f6f6f6;
} */
.create-report-modal-inner form input#learningText {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.create-report-modal-inner .form-check-input[type="radio"] {
  height: 20px;
  width: 20px;
  margin-right: 12px;
  background-color: transparent;
  border: 2px solid #f6f6f6;
  cursor: pointer;
  box-shadow: none;
}
.create-report-modal-inner .conclusion-factor-btn {
  color: #f6f6f6;
  background-color: #404040;
  font-size: 13px;
  width: 100%;
  max-width: 100%;
}

.create-report-modal-inner .conclusion-factor-btn:hover {
  background-color: #003ed4;
}
.create-report-modal-inner .bg-primary {
  background-color: #003ed4 !important;
}
.create-report-modal-inner .react-emoji{
  margin-top: 0px;
  margin-bottom: 15px;
  background-color: #222222;
  border: 1px solid #404040;
}
.create-report-modal-inner  .react-input-emoji--container{
  background-color: #222222;
  border: none;
}

.create-report-modal-inner .fields-wrapper.success-creteria .form-check {
  width: 100%;
  max-width: fit-content;
  margin: 0px;
  display: flex;
  align-items: center;
  padding-left: 0px;
}
.create-report-modal-inner
  .fields-wrapper.success-creteria
  .form-check
  > label {
  display: flex;
  align-items: center;
  margin-bottom: 0px!important;
}
.create-report-modal-inner
  .fields-wrapper.success-creteria
  .form-check
  > label svg{
    display: none;
  }
  .create-report-modal-inner
  .fields-wrapper.success-creteria
  .form-check input{
    margin-top: 0px;
    margin-right: 0px;
  }
.form-wrapper .form-wrapper-inner form .rmsc .dropdown-container {
  border-radius: 5px;
}
.form-wrapper .form-wrapper-inner form .rmsc .dropdown-heading {
  height: 42px;
}
.ReactTags__selected {
  margin-bottom: 10px;
}
.ReactTags__selected > span {
  background: #f1f1f1;
  padding: 5px 5px;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 4px;
  display: inline-block;
}
.control-variant-image-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
body form button.btn-as-link,
button.btn-as-link {
  border: none;
  background-color: transparent;
  color: #000;
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
}
.control-variant-image-container > div {
  max-width: 45%;
  width: 100%;
  height: 150px;
  border: 1px solid #dfe2e8;
  border-radius: 5px;
  background-position: center;
  background-size: 86%;
  background-repeat: no-repeat;
  position: relative;
}
.acc-newselect--is-disabled .acc-newselect__control {
  background-color: #fff9;
  /* color: #000; */
}
.control-variant-image-container > div::before {
  content: "";
  text-transform: capitalize;
  margin-bottom: 0px;
  font-size: 11px;
  position: absolute;
  padding: 0px 5px;
  left: 10px;
  top: -8px;
  background: #fff;
  pointer-events: none;
  z-index: 1;
}
.control-variant-image-container > div.control-image::before {
  content: "Control Image";
}
.control-variant-image-container > div.variant-image::before {
  content: "Variant Image";
}
.control-variant-image-container > div.client-logo::before {
  content: "Client Logo";
}
.upload-icon {
  padding: 5px 15px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  font-size: 25px;
}

.upload-icon svg {
  border-radius: 100%;
  background-color: #f1f1f1;
  padding: 4px;
}
.ReactTags__selected > span > button {
  border: none;
  padding: 0px 0px;
  margin-left: 5px;
  color: red;
  cursor: pointer;
}
.form-wrapper .testlivebtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  margin-bottom: 15px;
}
.form-wrapper .testlivebtn > span {
  margin-left: 15px;
}
.form-wrapper
  .form-wrapper-inner
  form
  button.submit-class:not(.clear-selected-button):not(.ReactTags__remove):not(.testlivebtn) {
  background: #070707;
  margin: 0px auto;
  display: block;
  max-width: 250px;
  width: 100%;
  color: #fff;
  height: 45px;
  border: none;
  border-radius: 5px;
}
.setting-bar-left > p {
  margin-bottom: 0px;
  font-size: 10px;
  margin-top: 8px;
  padding: 0px 10px;
  color: #fff;
}
.setting-bar-left {
  right: 0;
  position: absolute;
  bottom: 0;
  padding: 10px 10px;
  background: #000;
  left: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.setting-bar-left > .setting-bar-left-inner {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.setting-bar-left > .setting-bar-left-inner a {
  text-decoration: none;
  display: flex;
  max-width: 48%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.setting-bar-left > .setting-bar-left-inner a span {
  font-size: 14px;
  color: #fff;
  margin-top: 5px;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}
.setting-bar-left > .setting-bar-left-inner a svg {
  fill: #fff;
  font-size: 14px;
}
.cta-headers > button:not(.btn-as-link) {
  background-color: #070707;
  margin: 0px 0px 30px auto;
  display: block;
  max-width: 353px;
  width: 100%;
  color: #ebebeb;
  /* height: 45px; */
  border-radius: 5px;
  border: none;
}
.cta-headers .add-sheet-link-btn {
  background-color: var(--primary-color) !important;
}
.form-wrapper .form-wrapper-inner.scrubber > .card {
  padding: 35px 5px 0px;
}
.form-wrapper .form-wrapper-inner > .card {
  padding: 25px 20px;
}
.form-wrapper .form-wrapper-inner > .password-card {
  background-color: #121212 !important;
  max-height: 80vh;
  overflow: auto;
}
button.close-modal {
  position: absolute;
  top: 20px;
  right: 40px;
  background: transparent;
  border: none;
}

button.close-modal svg {
  width: 15px;
  height: 15px;
  fill: #ebebeb;
}
.breadcrumb-item.active {
  color: #6c757d;
  text-transform: capitalize;
}
.dropdown-content input {
  display: none !important;
}

@media (max-width: 1260px) {
  .calculated-boxes-inner > div {
    margin: 2px 0px;
    flex: 1 1 32%;
    max-width: 32%;
    width: 100%;
  }
  .port-cards > div.portfolio-creater{
    max-width: 48%;
  }
  .port-cards{
    column-gap: calc(4% / 1);
  }
}

@media (max-width: 1023px) {
  
  .calculated-boxes-inner > div {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .authlayout-inner .authsidebar {
    width: 100%;
    max-width: 225px;
  }
  .authlayout-inner .authcontent {
    max-width: calc(100% - 225px);
    margin-left: calc(225px);
  }
  /* .form-wrapper, .create-report-modal{ */
  /* max-width: calc(100% - 225px); */
  /* margin-left: calc(225px); */
  /* } */
  .navbar.authnavbar {
    max-width: calc(100% - 225px);
    margin-left: calc(225px);
  }
}
@media (max-width: 768px){
  .p-24{
    padding: 14px;
  }
  .p-24-top{
    padding-top: 14px;
  }
  .p-24-bottom{
    padding-bottom: 14px;
  }
  .p-20{
    padding: 14px;
  }
  .p-20-top{
    padding-top: 14px;
  }
  .p-20-bottom{
    padding-bottom: 14px;
  }
}
@media (max-width: 535px) {
  .port-cards > div.portfolio-creater{
    max-width: 100%;
  }
  .faq-wrapper > .faq-inner {
    max-width: 360px;
    padding: 15px 20px;
  }
  .faq-wrapper .faq-question-inner .heading h3 {
    font-size: 15px;
  }
  .faq-hero h2 {
    font-size: 17px;
  }
  .accordion__icon {
    width: 15px;
    height: 8px;
  }
  .faq-wrapper form input {
    font-size: 12px;
    padding-left: 38px;
    height: 40px;
  }
  .faq-search .search-icon svg {
    width: 18px;
    height: 18px;
  }
  .faq-wrapper > .faq-inner .description * {
    font-size: 13px;
  }
  .faq-wrapper > .faq-inner .description-wrapper {
    padding: 24px 0px;
  }
  .faq-wrapper .faq-heading {
    padding-bottom: 15px;
  }
  .faq-wrapper form {
    margin-top: 16px;
    margin-bottom: 1px;
  }
  .faq-wrapper .faq-question-inner .heading h3 {
    max-width: 85%;
  }
}
@media (max-width: 425px) {
  .create-report-modal-inner,
  .add-sheet-link-modal .form-wrapper-inner {
    max-width: 360px;
  }
  .create-report-modal-inner > .card .card-body,
  .add-sheet-link-modal .card {
    padding: 20px;
  }
}
